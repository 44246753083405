export const EmailRegExp =
  /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*(\.[a-zA-Z]{2,})$/i;

export const ID_VALIDATION_REGEXP = /^([a-z0-9]){4,20}$/;

export const PasswordRegExp =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/i;

export const NEGATIVE_ALPHABET_NUMERIC_SPECIAL_CHARACTER_REGEX =
  /[^a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>?]/g;

export const REGEX_PHONE_NUMBER = /^[0-9-]{0,15}$/; // v1.3.4
